<!-- eslint-disable max-len -->
<template>
  <div class="member_show">
    <div class="show_title">
      <img src="@/assets/img/account/icon_title_5.svg" alt="">
      <h4 class="txt-bold">我的優惠</h4>
    </div>

      <div class="show_discount">
        <div class="tagset">
          <router-link
            :to="{ name: 'MemberDiscount' }"
            class="tag">
              <img src="@/assets/img/account/icon_coupon.svg" alt="">
              <p>折價券</p>
          </router-link>
          <router-link
            :to="{ name: 'MemberDiscountNoShip' }"
            class="tag active">
              <img src="@/assets/img/account/icon_shippingfree.svg" alt="">
              <p>免運優惠</p>
          </router-link>
        </div>

        <div class="remaining">
          <h6 class="txt-right">
            剩餘次數: <span class="txt-blue txt-bold">5</span>
          </h6>
        </div>

        <div class="cart_head">
          <div class="cart_tr txt-grey">
            <div class="cart_td td-date"><small>發送時間</small></div>
            <div class="cart_td td-note"><small></small></div>
            <div class="cart_td td-worth txt-center"><small>有效日期</small></div>
            <div class="cart_td td-date txt-center"><small>次數</small></div>
          </div>
        </div>

        <div class="cart_item_box">
          <div class="cart_item">
            <div class="cart_tr aic">
              <div class="cart_td td-date center" data-rwd="發送時間" >
                <div>
                  <p>2020/08/09</p>
                </div>
              </div>
              <div class="cart_td td-note">
                <h6 class="note_name txt-bold" style="padding-left: 10px;">解放出遊去！免運優惠大放送</h6>
              </div>
              <div class="cart_td td-date center" data-rwd="有效日期" >
                <div>
                  <p>2020/08/09</p>
                </div>
              </div>
              <div class="cart_td td-worth center" data-rwd="次數" >
                <p class="txt-blue txt-bold">2</p>
              </div>
            </div>
          </div>
        </div>

        <Paginate/>

      </div>

  </div>
</template>

<script>
import Paginate from '@/components/Paginate.vue';

export default {
  name: 'MemberDiscountNoShip',
  components: {
    Paginate,
  },
  data() {
    return {
    };
  },
};
</script>
